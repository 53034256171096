
import * as ipxRuntime$wUC0WPqN2Z from '/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@nuxt+image@1.7.0_idb-keyval@6.2.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.1/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "mcstaging.miraco.com.eg"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$wUC0WPqN2Z, defaults: {} }
}
        